import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

import materialRoutes from "app/views/material-kit/MaterialRoutes";

// import Sales from "app/views/samples/Sales";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("app/views/sessions/JwtRegister")));
const ForgotPassword = Loadable(lazy(() => import("app/views/sessions/ForgotPassword")));
// E-CHART PAGE
const AppEchart = Loadable(lazy(() => import("app/views/charts/echarts/AppEchart")));
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));

// New pages
const Sales = Loadable(lazy(() => import("app/views/samples/Sales")));
const Inventory = Loadable(lazy(() => import("app/views/samples/Inventory")));
const Finance = Loadable(lazy(() => import("app/views/samples/Finance")));
const Traffic = Loadable(lazy(() => import("app/views/samples/Traffic")));
const Bizdev = Loadable(lazy(() => import("app/views/samples/Bizdev")));
const Maintenance= Loadable(lazy(() => import("app/views/samples/Maintenance")));
const Execom= Loadable(lazy(() => import("app/views/samples/Execom")));

// tables
const SalesQuotation= Loadable(lazy(() => import("app/views/tables/SalesQuotation")));
const SalesForDeployment= Loadable(lazy(() => import("app/views/tables/SalesForDeployment")));
const SalesCustomers= Loadable(lazy(() => import("app/views/tables/SalesCustomers")));

// ViewSales
const ViewTable= Loadable(lazy(() => import("app/views/tables/ViewTable/ViewTable")));
const SalesAdd= Loadable(lazy(() => import("app/views/tables/ViewTable/SalesAdd")));
const SalesEdit= Loadable(lazy(() => import("app/views/tables/ViewTable/SalesEdit")));
const SalesViewAdd= Loadable(lazy(() => import("app/views/tables/ViewTable/SalesViewAdd")));
const SalesCustomerView= Loadable(lazy(() => import("app/views/tables/ViewTable/SalesCustomerView")));
const SalesCustomerAdd= Loadable(lazy(() => import("app/views/tables/ViewTable/SalesCustomerAdd")));

const InventoryAllLocations= Loadable(lazy(() => import("app/views/tables/InventoryAllLocations")));
const InventoryForDeployment= Loadable(lazy(() => import("app/views/tables/InventoryForDeployment")));
const InventoryCalendar= Loadable(lazy(() => import("app/views/tables/InventoryCalendar")));

const InventoryAdd= Loadable(lazy(() => import("app/views/tables/ViewTable/InventoryAdd")));

const FinanceNewCustomerApproval= Loadable(lazy(() => import("app/views/tables/FinanceNewCustomerApproval")));
const FinanceBilling= Loadable(lazy(() => import("app/views/tables/FinanceBilling")));

const TrafficWorkOrders= Loadable(lazy(() => import("app/views/tables/TrafficWorkOrders")));
const TrafficThirdPartyRequests= Loadable(lazy(() => import("app/views/tables/TrafficThirdPartyRequests")));
const TrafficStatusUpdates= Loadable(lazy(() => import("app/views/tables/TrafficStatusUpdates")));

const TrafficAdd= Loadable(lazy(() => import("app/views/tables/ViewTable/TrafficAdd")));

const BizDevPotentialSites= Loadable(lazy(() => import("app/views/tables/BizDevPotentialSites")));
const BizDevExpiringContracts= Loadable(lazy(() => import("app/views/tables/BizDevExpiringContracts")));



const MaintenanceLUT = Loadable(lazy(() => import("app/views/tables/ViewTable/MaintenanceLUT")));

const LookupArea = Loadable(lazy(() => import("app/views/tables/ViewTable/LookupTable/LookupArea")));
const LookupType = Loadable(lazy(() => import("app/views/tables/ViewTable/LookupTable/LookupType")));
const LookupLocation = Loadable(lazy(() => import("app/views/tables/ViewTable/LookupTable/LookupLocation")));
const LookupPT = Loadable(lazy(() => import("app/views/tables/ViewTable/LookupTable/LookupPT")));



const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      { path: "/dashboard/default", element: <Analytics />, auth: authRoles.admin },
      // e-chart route
      { path: "/charts/echarts", element: <AppEchart />, auth: authRoles.editor },

      { path: "/samples/Sales", element: <Sales /> },
      { path: "/samples/Inventory", element: <Inventory /> },
      { path: "/samples/Finance", element: <Finance /> },
      { path: "/samples/Traffic", element: <Traffic /> },
      { path: "/samples/Bizdev", element: <Bizdev /> },
      { path: "/samples/Execom", element: <Execom /> },
      { path: "/samples/Maintenance", element: <Maintenance /> },

      { path: "/tables/SalesQuotation", element: <SalesQuotation /> },
      { path: "/tables/SalesForDeployment", element: <SalesForDeployment /> },
      { path: "/tables/SalesCustomers", element: <SalesCustomers /> },

      { path: "/tables/ViewTable", element: <ViewTable /> },
      { path: "/tables/SalesAdd", element: <SalesAdd /> },
      { path: "/tables/SalesEdit", element: <SalesEdit /> },
      { path: "/tables/SalesViewAdd", element: <SalesViewAdd /> },
      { path: "/tables/SalesCustomerView", element: <SalesCustomerView /> },
      { path: "/tables/SalesCustomerAdd", element: <SalesCustomerAdd /> },

      { path: "/tables/InventoryAllLocations", element: <InventoryAllLocations /> },
      { path: "/tables/InventoryForDeployment", element: <InventoryForDeployment /> },
      { path: "/tables/InventoryCalendar", element: <InventoryCalendar /> },

      { path: "/tables/InventoryAdd", element: <InventoryAdd/> },

      { path: "/tables/FinanceNewCustomerApproval", element: <FinanceNewCustomerApproval /> },
      { path: "/tables/FinanceBilling", element: <FinanceBilling /> },

      { path: "/tables/TrafficWorkOrders", element: <TrafficWorkOrders /> },
      { path: "/tables/TrafficThirdPartyRequests", element: <TrafficThirdPartyRequests /> },
      { path: "/tables/TrafficStatusUpdates", element: <TrafficStatusUpdates /> },

      { path: "/tables/TrafficAdd", element: <TrafficAdd /> },

      { path: "/tables/BizDevPotentialSites", element: <BizDevPotentialSites /> },
      { path: "/tables/BizDevExpiringContracts", element: <BizDevExpiringContracts /> },

      { path: "/maintenance/maintenanceLUT", element: <MaintenanceLUT /> },
      { path: "/maintenance/LookupArea", element: <LookupArea/> },
      { path: "/maintenance/LookupType", element: <LookupType/> },
      { path: "/maintenance/LookupLocation", element: <LookupLocation/> },
      { path: "/maintenance/LookupPT", element: <LookupPT/> },
    ]
  },
  // New Pages
  // { path: "/samples/Sales", element: <Sales /> },
  // { path: "/samples/Inventory", element: <Inventory /> },


  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <Navigate to="dashboard/default" /> },
  { path: "*", element: <NotFound /> }


  

];

export default routes;
